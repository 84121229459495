import * as React from "react";

const FeaturesSection = ({ title, description, children }) => {
  const isOdd = React.Children.map(children, (child, i) => {
    if (i < React.Children.toArray(children).length / 2) return false;
    else return true;
  });

  return (
    <div className="relative bg-white py-16 lg:py-24">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          {title}
        </h2>
        {description && (
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            {description}
          </p>
        )}
        <div className="mt-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 features">
            {children}
          </div>
        </div>
      </div>
    </div>
    // <div className="bg-white">
    //   <div className="max-w-7xl mx-auto px-4 pt-8 sm:pt-12 sm:px-6">
    //     <div className="bg-gradient-to-r from-amber-600 to-amber-500 rounded-2xl px-6 py-16 sm:p-16">
    //       <div className="max-w-xl mx-auto lg:max-w-none">
    //         {title && (
    //           <div className="text-center">
    //             <h2 className="text-2xl font-extrabold tracking-tight text-white">
    //               {title}
    //             </h2>
    //           </div>
    //         )}
    //         <div className="mt-12 max-w-sm mx-auto grid sm:grid-cols-2 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3">
    //           {isOdd.map((val, i) => {
    //             if (!val || React.Children.toArray(children).length % 2 === 0)
    //               return children[i];
    //             else {
    //               return (
    //                 <div className="lg:flex lg:justify-end ">{children[i]}</div>
    //               );
    //             }
    //           })}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default FeaturesSection;
