import React from "react";
import {
  CreditCardIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

import Header from "../components/sections/Header";
import ServicesSection from "../components/sections/ServicesSection";
import ServiceItem from "../components/elements/ServiceItem";
import FeaturesSection from "../components/sections/FeaturesSection";
import FeatureItem from "../components/elements/FeatureItem";
import Footer from "../components/sections/Footer";
import PageTitle from "../components/elements/PageTitle";

const CompanyEnPage = () => (
  <div className="bg-white niramit-regular">
    <PageTitle pageTitle={"Axxtrans Company"} description={``} />
    <Header lang="en" />
    <ServicesSection>
      <ServiceItem
        title={""}
        description={"Axxtrans access system"}
        paragraphs={[
          `To promote the city through digitization of local passes: tourist passes for visitors and culture or leisure cards for resident.`,
          `To create an economic and social network between end-users and local service providers in order to enhance customer relationship management.`,
          `To increase the number of visits to cultural and recreational sites. To foster the consumption of local services.`,
        ]}
        imgName={"axxpay-image2.png"}
      />
      <ServiceItem
        title={""}
        description={"SoftPos"}
        paragraphs={[
          `It's easier than you think. After the download, you can be up and running in no time. On any Android device with NFC, you'll soon be ready to accept payments with our Axxtrans app. From credit cards and wallets to chips and smartwatches, we've got you covered for every transaction.`,
        ]}
        imgName={"axxpay-image3.png"}
      />
      <ServiceItem
        title={""}
        description={"Turn your device into a payment terminal"}
        paragraphs={[
          `With Axxtrans, your Android-based device can turn into payment terminal (check requirements). Our technology is perfect for you. In just a few minutes, you can set up wireless online payments on your preferred device and seamlessly integrate it into your business`,
        ]}
        imgName={"axxpay-main-image.png"}
      />
    </ServicesSection>
    <FeaturesSection title="Make your customers feel safe" description="">
      <FeatureItem
        title="Take payments anywhere"
        description="Transform most Android 8+ devices with NFC into payment terminals that accept Visa, Mastercard, as well as Apple Pay, Google Pay, and Samsung Pay"
        Icon={CreditCardIcon}
      />
      <FeatureItem
        title="Cancel payments and issue refunds"
        description="Made a mistake? No problem! Axxtrans supports payment cancellation or refund to your customer's card"
        Icon={XCircleIcon}
      />
      <FeatureItem
        title="With or without a PIN"
        description="Make your customers feel secure with a certified integrated PIN code module, allowing you to accept payments of any amount. A truly mobile payment terminal that you can take anywhere"
        Icon={ShoppingBagIcon}
      />
      <FeatureItem
        title="Standalone or integrated"
        description="Already have loyalty programs, ongoing discounts, or other payment systems installed? Through Axxtrans API, seamlessly integrate your existing solutions. You can also use Axxtrans payment app independently, tailored to your needs"
        Icon={RefreshIcon}
      />
      <FeatureItem
        title="Security"
        description="The solution is certified according to EMV and PCI security standards"
        Icon={ShieldCheckIcon}
      />
    </FeaturesSection>
    <Footer lang="en" />
  </div>
);

export default CompanyEnPage;
