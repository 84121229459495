import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { useImageForData } from "../../hooks/useAllImages";

const ServiceItem = ({ title, description, paragraphs, imgName }) => (
  <div className="border-t border-gray-200 pt-8 mt-8 md:pt-12 mt-12 md:grid grid-flow-col md:grid-cols-2 md:gap-x-24 items-center service">
    <div>
      <h2 className="text-base font-semibold text-amber-600 tracking-wide uppercase">
        {title}
      </h2>
      <p className="mt-2 text-3xl font-extrabold text-[#06293d]">
        {description}
      </p>
      {paragraphs.length > 0 &&
        paragraphs.map((text, i) => (
          <p key={i} className="mt-4 text-lg text-gray-500">
            {text}
          </p>
        ))}
      {/* <p className="mt-4 text-lg text-gray-500">{content}</p> */}
    </div>
    <div className="mt-4 md:mt-0 rounded-lg shadow-lg service_img">
      <GatsbyImage
        image={useImageForData(imgName)}
        alt={title}
        className="rounded-lg !shadow-lg object-cover object-center w-full h-full"
      />
    </div>
  </div>
);

export default ServiceItem;
