import React from "react";

const ServicesSection = ({ children }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    {/* services */}
    {children}
  </div>
);

export default ServicesSection;
